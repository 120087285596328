<template>
  <div
    class="pagination-buttons inline-flex h-[32px] md:h-[48px] max-w-full mt-4 mx-auto"
  >
    <AppButton
      class="max-md:hidden variant-outline rounded-l min-w-[100px]"
      :disabled="value - 1 <= 0"
      rel="prev"
      v-bind="{
        [static === 'without-router' ? 'href' : 'to']: getPageUrl(value - 1)
      }"
      @click="goToPage(value - 1)"
    >
      {{ $t('search.previous') }}
    </AppButton>
    <AppButton
      class="md:hidden variant-outline rounded-l"
      :disabled="value - 1 <= 0"
      :icon="mdiChevronLeft"
      rel="prev"
      v-bind="{
        [static === 'without-router' ? 'href' : 'to']: getPageUrl(value - 1)
      }"
      @click="goToPage(value - 1)"
    >
    </AppButton>
    <template v-for="(page, index) in visiblePages">
      <AppButton
        v-if="page === -1"
        :key="`${page}-${index}-${value}-divider`"
        disabled
        class="variant-outline"
      >
        ...
      </AppButton>
      <AppButton
        v-else
        :key="`${page}-${index}-${value}`"
        class="variant-outline"
        :class="{
          active: page === value,
          'max-sm:hidden':
            Math.abs(page - value) > 1 && page > 1 && page < total - 2
        }"
        v-bind="{
          [static === 'without-router' ? 'href' : 'to']: getPageUrl(page)
        }"
        @click="goToPage(page)"
      >
        <span class="">
          {{ page }}
        </span>
      </AppButton>
    </template>
    <AppButton
      class="max-md:hidden variant-outline rounded-r md:min-w-[100px]"
      :disabled="value + 1 > total"
      rel="next"
      v-bind="{
        [static === 'without-router' ? 'href' : 'to']: getPageUrl(value + 1)
      }"
      @click="goToPage(value + 1)"
    >
      {{ $t('search.next') }}
    </AppButton>
    <AppButton
      class="md:hidden variant-outline rounded-r"
      :disabled="value + 1 > total"
      :icon="mdiChevronRight"
      rel="next"
      v-bind="{
        [static === 'without-router' ? 'href' : 'to']: getPageUrl(value + 1)
      }"
      @click="goToPage(value + 1)"
    >
    </AppButton>
  </div>
</template>

<script setup lang="ts">
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: Number,
    default: () => 1
  },
  total: {
    type: Number,
    default: () => 1
  },
  windowSize: {
    type: Number,
    default: () => 3
  },
  endingWindowSize: {
    type: Number,
    default: () => 2
  },
  static: {
    type: [Boolean, String] as PropType<boolean | 'without-router'>,
    default: () => false
  },
  baseUrl: {
    type: String,
    default: () => ''
  }
});

type Emits = {
  (e: 'update:modelValue', value: number, oldValue?: number): void;
};

const emit = defineEmits<Emits>();

const value = ref(props.modelValue);

watch(
  () => props.modelValue,
  v => {
    value.value = v;
  }
);

watch(
  () => value.value,
  v => {
    emit('update:modelValue', v);
  }
);

const visiblePages = computed(() => {
  const ranges: [number, number][] = [];
  const halfWindowDown = Math.floor(props.windowSize / 2);

  if (value.value < props.windowSize) {
    ranges.push([1, props.windowSize]);
  } else {
    ranges.push([1, props.endingWindowSize]);
  }
  ranges.push([value.value - halfWindowDown, value.value + halfWindowDown]);
  if (value.value > props.total - props.windowSize) {
    ranges.push([props.total - props.windowSize + 1, props.total]);
  } else {
    ranges.push([props.total - props.endingWindowSize + 1, props.total]);
  }

  const pages: number[] = [];

  ranges.forEach(([start, end]) => {
    for (let i = start; i <= end; i++) {
      if (!pages.includes(i) && i > 0 && i <= props.total) {
        pages.push(i);
      }
    }
  });

  pages.sort((a, b) => a - b);

  const withDividers: number[] = [];

  pages.forEach((page, index) => {
    if (index === 0) {
      withDividers.push(page);
    } else {
      const previousPage = pages[index - 1];
      if (page - previousPage >= 2) {
        withDividers.push(-1);
      }
      withDividers.push(page);
    }
  });

  return withDividers;
});

function goToPage(page: number) {
  if (props.static || page < 1 || page > props.total) {
    return;
  }
  value.value = page;
}

function getPageUrl(page: number) {
  if (!props.static) {
    return null;
  }
  return (
    `${props.baseUrl}` + (page > 1 ? `/${page}-${t('paramsKeys.page')}` : '')
  );
}
</script>

<style lang="scss">
.pagination-buttons {
  @apply bg-white rounded-[12px];
  & > button,
  & > a {
    @apply text-sm md:text-base px-0.5 md:px-2 min-w-[24px] md:min-w-[40px];

    &:first-child {
      @apply rounded-l;
    }
    &:not(:first-child) {
      @apply ml-[-0.9px];
    }
    &:last-child {
      @apply rounded-r;
    }
    &.active {
      @apply bg-neutral-light text-white pointer-events-none;
    }
    &:disabled {
      @apply text-neutral-light;
    }
  }
}
</style>
